import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap'

import ArticleAboutModal from './article-about-modal'
import {
  ArticleWrapper,
  ArticleContainer,
  ArticlePersonWrapper,
  ArticlePersonPhotoColWrapper,
  ArticlePersonPhotoWrapper,
  ArticlePersonAboutColWrapper,
  ArticlePersonDescColWrapper,
  ArticlePersonDescWrapper,
} from '../elements'

const Article = (props) => {
  const data = useStaticQuery(graphql`
    query {
      Article1Image1: file(relativePath: { eq: "parent/article8/image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image2: file(relativePath: { eq: "parent/article8/image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image3: file(relativePath: { eq: "parent/article8/image3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ArticleWrapper>
      <ArticleContainer>
        <ArticlePersonDescWrapper>
          <Row className="marginTop90">
            <Col xs={12}>
              <p>
                Zacznijmy od definicji kieszonkowego, opracowanej przez Kapitułę
                programu Misja Kieszonkowe.
              </p>
              <p>
                Kieszonkowe to powtarzalne, regularne i stałe w kwocie środki
                finansowe przekazywane bezwarunkowo przez opiekunów do
                samodzielnej dyspozycji dziecka. Dysponowanie nim powinno
                odbywać się według wspólnie ustalonych zasad dotyczących ich
                wydatkowania. Celem kieszonkowego jest nauka gospodarowania
                własnymi pieniędzmi (m.in. planowanie bieżących wydatków,
                konsekwencji własnych decyzji finansowych, oszczędzania,
                inwestowania w siebie, charytatywności) oraz pogłębianie wiedzy
                o finansach (m.in. o wartości pieniądza, wycenie pracy,
                nominałach, nowoczesnych narzędziach finansowych).
              </p>
            </Col>
            <Col xs={12} lg={6} className="ArticlePersonDescWrapperFlexVC">
              <p>
                Jeśli do kieszonkowego podejdziemy w ten sposób – ustalając
                stałą, rozsądną kwotę przyznawaną regularnie na wydatki
                określone rodzinną umową i z wyraźnymi celem edukacyjnym – to
                już pierwszy krok do sukcesu!
              </p>
              <p>
                Podobnie jak w świecie dorosłych w świecie dzieci zbyt szybkie i
                łatwe wzbogacanie się może być demoralizujące.
              </p>
              <p>
                <i>
                  Dobrze jest opracować system bonusów, czyli możliwość
                  podwyższenia kieszonkowego w sytuacji, w której dziecko
                  osiągnie jakieś szczególne sukcesy albo wykona jakieś
                  konkretne czynności. Samo kieszonkowe pozostaje wtedy
                  świadczeniem bezwarunkowym, ale jednocześnie dziecko otrzymuje
                  możliwość zapracowania na dodatkowe wynagrodzenie. Dzięki temu
                  od początku wie, że czasem trzeba się zmęczyć, żeby uzyskać
                  pieniądze.
                </i>{' '}
                – przekonuje dziennikarz ekonomiczny i bloger Maciej Samcik.
              </p>
            </Col>
            <Col xs={12} lg={6}>
              <Img
                fluid={data.Article1Image1.childImageSharp.fluid}
                className="articleImage"
                alt="Twoje dziecko myśli, że kieszonkowe spada z nieba? Możesz to zmienić!"
              />
            </Col>
            <Col xs={12} className="marginTop30">
              <p>
                Badanie BNP Paribas pokazuje też, że mimo świadomości tego, że
                dzieci nie potrafią racjonalnie dysponować pieniędzmi i trwonią
                je na zachcianki, bardzo rzadko rozmawia się w domu o
                pieniądzach. Jednocześnie aż 77% rodziców sądzi, że edukacja
                finansowa dzieci jest ważna i potrzebna. Taki dysonans rodzi
                wiele napięć. Dominuje frustracja z powodu braku szacunku
                latorośli do pieniądza i lęk, że takie zachowania wyniesione z
                dzieciństwa przełożą się na dorosłe problemy.
              </p>
            </Col>
            <Col xs={12}>
              <div className="imgWrapper">
                <Img
                  fluid={data.Article1Image2.childImageSharp.fluid}
                  className="articleImage"
                  alt="Twoje dziecko myśli, że kieszonkowe spada z nieba? Możesz to zmienić!"
                />
              </div>
            </Col>
            <Col xs={12} className="marginTop30">
              <p>
                Natalia Tur, socjolożka i mediatorka rodzinna, radzi, by w
                takich momentach oceniać sytuację – nie dziecko: Przydatnym i
                wspierającym rozmowę narzędziem jest asertywna technika FUO,
                zgodnie z którą najpierw warto powiedzieć o zaobserwowanych
                faktach, potem uczuciach, a następnie oczekiwaniach. I
                przykładowo zwrócić się do dziecka słowami:{' '}
                <i>
                  „Zauważyłam, że przez ostatnie cztery tygodnie sygnalizujesz
                  całkowity brak pieniędzy po dwóch dniach od otrzymania
                  kieszonkowego. Czuję złość i jest mi przykro, bo poprzez
                  kieszonkowe chciałabym nauczyć Cię rozsądnego gospodarowania
                  pieniędzmi. Czy mógłbyś uważniej przyjrzeć się swoim wydatkom
                  i pomyśleć, z czego wynika brak gotówki w połowie tygodnia i
                  co można zrobić, żeby to zmienić? Jeśli chcesz, razem możemy
                  się nad tym zastanowić”
                </i>
                .
              </p>
            </Col>
            <Col xs={12} lg={6}>
              <Img
                fluid={data.Article1Image3.childImageSharp.fluid}
                className="articleImage"
                alt="Twoje dziecko myśli, że kieszonkowe spada z nieba? Możesz to zmienić!"
              />
            </Col>
            <Col
              xs={12}
              lg={6}
              className="ArticlePersonDescWrapperFlexVC marginTop30"
            >
              <p>
                Lekcją szacunku do pieniądza jest też wspólne omawianie z
                dzieckiem domowego budżetu. Monika Sajkowska, prezeska Fundacji
                Dajemy Dzieciom Siłę, radzi wręcz, by otwarcie rozmawiać z
                dziećmi o domowym budżecie i np. dziennych zarobkach rodziców,
                by uświadomić, jak długo trzeba pracować na kieszonkowe.
              </p>
              <p>
                <i>
                  Budżet rodziny można pokazać w arkuszu Excela, na kartce
                  papieru lub używając różnej wielkości klocków. Ile kosztuje
                  utrzymanie rodziny, jakie są koszty jedzenia, opłat za
                  mieszkanie, przedszkola brata, leczenia babci, dojazdów do
                  pracy i szkoły itd. Czy i ile zostaje po opłaceniu
                  najważniejszych kosztów. Na co niezbędnie potrzebna jest ta
                  rezerwa, a ile można przeznaczyć na zachcianki i przyjemności
                  wszystkich członków rodziny. Współuczestniczenie w decyzjach
                  rodziców uczy dziecko współodpowiedzialności za rodzinę
                </i>{' '}
                – przekonuje Monika Sajkowska.
              </p>
            </Col>
            <Col xs={12} className="marginTop30">
              <p>
                <strong>
                  Z{' '}
                  <a href="/misjasamodzielnosc/strefa-rodzica">
                    https://www.bnpparibas.pl/misjasamodzielnosc/strefa-rodzica
                  </a>{' '}
                  możesz pobrać „Kieszonkowy manual rodzica” – praktyczny
                  przewodnik przygotowany przez specjalistów programu. Warto
                  spróbować wprowadzić te metody w życie i przekonać się, jak
                  zadziałają w Waszym domu.{' '}
                </strong>
              </p>
            </Col>
            <Col xs={12} className="marginTop30">
              <p>Materiał przygotowany we współpracy z członkami Kapituły.</p>
            </Col>
            <Col xs={12} className="marginTop30 small">
              <p>
                <sup>1</sup> BNP Paribas, Badanie postaw rodziców wobec
                kieszonkowego. Raport z badania jakościowo-ilościowego, 2022.
              </p>
            </Col>
          </Row>
        </ArticlePersonDescWrapper>
      </ArticleContainer>
    </ArticleWrapper>
  )
}

export default Article
