import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ArticleHeader from '../../components/parent/article-header'
import Article8 from '../../components/parent/article8'
import UsefulTools from '../../components/parent/useful-tools'

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/article8/article-header8.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/gry.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool2: file(relativePath: { eq: "parent/tools/krzyzowki.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="Twoje dziecko myśli, że kieszonkowe spada z nieba? Możesz to zmienić!"
        description="Przelew od rodziców, gotówka od dziadków, koperta w prezencie – badanie BNP Paribas (2022)1  pokazuje, że polskie dzieci są wprost zalewane kasą. Jak w takich warunkach uczyć je szacunku do pieniądza?"
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        date="12.04.2021"
        title={
          <>
            Twoje dziecko myśli,
            <br />
            że kieszonkowe spada z nieba?
            <br />
            Możesz to zmienić!
          </>
        }
        desc={
          <p>
            Przelew od rodziców, gotówka od dziadków, koperta w{'\u00A0'}
            prezencie
            <br />– badanie BNP Paribas (2022)<sup>1</sup> pokazuje, że
            {'\u00A0'}polskie dzieci
            <br />
            są wprost zalewane kasą. Jak w{'\u00A0'}takich warunkach
            <br />
            uczyć je szacunku do pieniądza?
          </p>
        }
        linkPrev="/strefa-rodzica/baza-wiedzy"
        linkNext="/strefa-rodzica/baza-wiedzy#narzedzia"
      />
      <Article8 />
      <UsefulTools
        items={[
          {
            title: 'Krzyżówki',
            subtitle: 'Krzyżówka 2',
            image: data.Tool2,
            link: '/parent/Krzyzowka%202.pdf',
          },
          {
            title: 'Gry',
            subtitle: 'Budżet Samodzielniaka / Plansza i instrukcja',
            image: data.Tool1,
            link: '/parent/Budzet_Samodzielniaka_plansza_instrukcja.pdf',
          },
          {
            title: 'Gry',
            subtitle: 'Bankosłówka',
            image: data.Tool1,
            link: '/parent/Bankoslowka.pdf',
          },
        ]}
      />
    </Layout>
  )
}

export default IndexPage
